import {SessionContext} from "components/providers/session";
import {useContext} from "react";

export default function formatPrice(amount) {
	const session = useContext(SessionContext);

	const languageCode = session.config.language.languageCode;
	const currency = typeof session.currency.isoCode !== 'undefined' ? session.currency.isoCode : session.config.currency;

	function isFraction(number) {
		return number % 1 !== 0;
	}

	return session.currency ? Intl.NumberFormat(languageCode, {
		style: 'currency',
		currency: currency,
		minimumFractionDigits: isFraction(amount) ? 2 : 0,
		maximumFractionDigits: 2
	}).format(amount) : amount;
}

export function getCurrencySymbol() {
	const session = useContext(SessionContext);

	const languageCode = session.config.language.languageCode;
	const currency = typeof session.currency.isoCode !== 'undefined' ? session.currency.isoCode : session.config.currency;

	return session.currency ? new Intl.NumberFormat(languageCode, { style: 'currency', currency })
    	.formatToParts(1)
    	.find(x => x.type === "currency")
    	.value : '€';
}